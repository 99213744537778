.HeaderContainer {
    /* padding: 30px ; */
    /* text-align: center; */
    background-color: #8ED1DC;
    border-bottom: 1px solid lightgray;
    box-shadow: 0 .01rem 1rem lightgray;
  }



#AppBar-image-Container {
    margin-left: 93%; 

}  

#UserImage {
    height: 55px;
    width: 55px;
   /* margin-left: 10px; */
    border-radius: 100px;
}