body{
  /* background:linear-gradient(to left, green, white); */
  margin:0;
  padding:0;
  box-sizing:border-box;
}

.container{
  display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
    width:100%;
    background-image: #fff;
    
}
  .form-box{
      width:380px;
      height:450px;
      border: 1px solid #e3e3e3;
      padding:20px;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      border-radius: 16px;
  }
.header-form{
  margin-bottom:15px;
}
/* .Login-logoImage {
  height: 100px;
  width: 100px;
 
} */
.imageDesign {
  height: 120px;
  width: 110px;
  justify-content: center;
  border-radius: 50%;
  justify-items: center;
  margin-left: 1%;
  margin-top: 15%;
  
}
.UserInput-section {
  margin-top: 10%;
}
.sign-button {
  width: 83%;
  height: 40px;
  margin-top: 3%;
  background-color: #04aa6d;
  color: #fff;
  border: none;
}
.username-Input {
  width: 80%;
  height: 35px;
  color: black;
  border: 1px solid #ced4da;
  
}
.password-Input {
  width: 80%;
  height: 35px;
  margin-top: 3%;
  color: black;
  border: 1px solid #ced4da;
}
.create-Acount {
  color: black;
  margin-top: 3%;
}
.signUp-text {
  color: #EA5252;
}



