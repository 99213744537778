body{
    /* background:linear-gradient(to left, green, white); */
    margin:0;
    padding:0;
    box-sizing:border-box;
  }
  

.SignUp-main-container { 
    display:flex;
    align-items:center;
    justify-content:center;
    height:100vh;
    width:100%;
    background-image: #fff; 
}
.Signup-area-container {
    width:380px;
    height:450px;
    /* border: 1px solid #EA5252; */
    border: 1px solid #e3e3e3;
   
}
.imageDesign2 {
    height: 120px;
    width: 110px;
    border-radius: 50%;
    margin-left: 1%;
    margin-top: 7%;   
}
  .UserInput-section {
    margin-top: 6%;
  }
  .fullname-Input {
    width: 80%;
    height: 35px;
    color: black;
    border: 1px solid #e3e3e3;

  }
 
  .username-Input {
    width: 80%;
    height: 35px;
    color: black;
    margin-top: 3%;
    border: 1px solid #e3e3e3;
  }
  .password-Input {
    width: 80%;
    height: 35px;
    margin-top: 3%;
    color: black;
    border: 1px solid #e3e3e3;
  }
  .create-Acount {
    color: black;
    margin-top: 3%;
  }
  .signUp-text {
    color: #EA5252;
  }
  .SigInLink {
   
    
  }
  
  
  
  